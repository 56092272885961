import React from 'react';
import htmr from 'htmr';
import styles from './Styles/staticpage.module.scss';
import Footer from './Shared/Footer';
import Header from './Shared/Header';
import Helmet from 'react-helmet';
import SEO from './SEO';
import schemaMarkup from '../../static/schema.json';
import PropTypes from 'prop-types';
import '../styles/cookieconsent.min.css';
import homePageData from '../../data/homepage/shawHomePage.json';

const HomePage= ({pageContext}) => {
  const pageData = pageContext.data;
  const pageSlug = pageContext.pageslug;
  const locale = pageContext.locale;
  const seoInfo = pageContext.seo;

  const canonicalList = seoInfo.canonicalurls;
  const canonicalUrls = [];
  if (canonicalList) {
    for (let index = 0; index < canonicalList.length; index++) {
      canonicalUrls.push({
        'href': canonicalList[index].href,
        'rel': 'canonical',
      });
    }
  }

  const metaVerify = '04e503c247d0a2c145b10baaa9a9f2f4';

  return (
    <div>
      <SEO
        locale={locale}
        title={seoInfo.title}
        description={seoInfo.description}
        canonicalUrls={canonicalUrls}
        languageCanonicals={homePageData.canonicalPages}
        schemaMarkup={schemaMarkup}
      />
      <Helmet>
        <script>
          window.dataLayer=window.dataLayer||[];
        </script>
      </Helmet>
      <Helmet>
        <script async src="/js/cookieconsent-scripts.min.js"></script>
      </Helmet>
      <Helmet>
        <meta name='classpert-site-verification' content={metaVerify} />
      </Helmet>
      <Header locale={locale} />
      <main className={`${pageSlug} ${styles.staticPage}`}>
        {pageData.map((section) => {
          let content = '<div></div>';
          if (section.pagesection) {
            content = section.pagesection.content;
          }
          return htmr(content);
        })}
      </main>
      <Footer />
    </div>
  );
};

HomePage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};

export default HomePage;
